body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.awssld__content {
    background: inherit !important;
}

.awssld__container {
    height: 100px !important;
}

.awssld__bullets--active {
    height: 12px !important;
    background: #B25C8A !important;
    border-radius: 12px !important;
}

.awssld__bullets button:not(.awssld__bullets--active) {
    background: #302B70;
}

.center-child-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-checkbox-inner {
    border-radius: 50% !important;
    background: none !important;
    border-color: #B25C8A !important;
}

.ant-checkbox-checked {
    background: #B25C8A;
}

.gradient-text {
    background-image: linear-gradient(88.38deg, #B25C8A 8.41%, #787BD1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-background {
    background-image: linear-gradient(88.38deg, #B25C8A 8.41%, #787BD1 100%) !important;
    border-color: unset !important;
    border-radius: 30px !important;
}

.gradient-background-btn {
    background-image: linear-gradient(88.38deg, #B25C8A 8.41%, #787BD1 100%) !important;
}

.gradient-background-outline {
    border-color: #B25C8A #787BD1 #B25C8A #787BD1;
}

.ant-modal .ant-modal-content {
    box-shadow: none;
}
.p-fixed.alert {
    position: fixed;
    top: 150px;
    right: 50px;
    z-index: 9999;
}

.reaction-button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 5px;
}

.reaction-button:hover {
    cursor: pointer
}

.reaction-icon-filled {
    width: 14px;
    height: 14px;
}

.center-child-items {
    /* margin-right: 6px; */
    font-size: 13px !important;
    color: #515151 !important;
    font-weight: 300;
}

.d-flex {
    display: flex;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.direction-col {
    display: flex;
    flex-direction: column;
}

.divider{
    margin: 5px;
    width: 38%;
    height: 1px;
    background-color: rgb(155, 155, 155);
    border: none;
}

.or-text{
    color: rgb(155, 155, 155);
}

.w-full {
    width: 100%;
}

.comment-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.comment-list .comment-li {
    padding: 10px 0;
    margin: 0;
    border-top: 0.75px solid rgba(155, 155, 155, 0.404);
}